<template>
  <header>  
    <b-navbar fixed="top" id="top-banner"> 
      <div class="flex-container">
        <h6> {{ $t('navblack.p1') }}: {{name}}</h6> 
        <b-button v-if="name.includes('JAVA')" :href="url" variant="aplicar">{{ $t('navblack.p2') }}</b-button>
        <b-button v-if="name.includes('MERN')" :href="url" variant="aplicar">{{ $t('navblack.p2') }}</b-button>
      </div>
    </b-navbar>
  <b-navbar toggleable="lg" id="nav" :type="nav.type" :variant="nav.variant">
    
    <b-navbar-brand to="/" class="ml-5">
      <img :src="require('../assets/'+nav.logo)" id="logo">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      
      <b-navbar-nav align="right" class="mr-5">
        <b-nav-item to="/somos" class="items">{{ $t('navwhite.navitem1.p1') }}<br>{{ $t('navwhite.navitem1.p2') }}</b-nav-item>
        <b-nav-item  to="/bootcamps" class="items">{{ $t('navwhite.navitem2.p1') }}<br>{{ $t('navwhite.navitem2.p2') }}</b-nav-item>
        <b-nav-item to="/conoce-mindhub" class="items">{{ $t('navwhite.navitem3.p1') }}<br>{{ $t('navwhite.navitem3.p2') }}</b-nav-item>
        <b-nav-item to="/empresas" class="items">{{ $t('navwhite.navitem4.p1') }}<br>{{ $t('navwhite.navitem4.p2') }}</b-nav-item>
        <li id="lang" class="nav-item items">
          <div class="nav-link">
            <template v-for="(language,index) in languages">
              <span
                :key="language.locale"
                @click="changeLanguage(language.locale)"
                :class="{'active-lang': language.locale == lang}">
                {{ language.title }}
              </span>
              <template v-if="index==0">|</template>
            </template>
          </div> 
        </li>
      </b-navbar-nav>
      
    </b-collapse>
    
  </b-navbar>
</header>
  
</template>
<style scoped>
header{
  position: absolute;
  width: 100%;
  z-index: 999;
}

.bg-blue a, .bg-pink a, .bg-blue #lang span, .bg-pink #lang span{
  color: #fff!important;
}

.bg-blue a:hover, .bg-pink a:hover, .bg-blue a.router-link-active, .bg-pink a.router-link-active, .bg-blue #lang .active-lang, .bg-pink #lang .active-lang {
  color: #BAFF43!important;
}

.bg-white a, .bg-white #lang span{
  color: #000!important;
}

.bg-white{
  background-color: #fff!important;
}

.bg-white a:hover, .bg-white a.router-link-active, .bg-white #lang .active-lang{
  color: #0000ff!important;
}

@media screen and (max-width: 992px) {
  .bg-blue{
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(0,0,255,0.5) 100%);
    
  }
  .bg-pink{
    background: rgb(2,0,36);
    background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(255,0,255,0.5) 100%);
    
  }
}

@media screen and (max-width: 425px){
  header{
    position: initial;
  }
}

#nav {
  background-color: transparent;
}

#nav .navbar-nav{
  width: 100%;
}

.nav-item{
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
@media screen and (min-width: 320px){
   #nav{
     margin-top: 60px;
   }
}
@media screen and (min-width: 768px){
   #nav{
     margin-top: 70px;
   }
}
@media screen and (min-width: 1100px) {
  .nav-item{
    padding-right: 1rem;
    padding-left: 1rem;
  }
}

@media screen and (min-width: 1200px) {
  .nav-item{
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

#logo{
  height: 50px;
  display: flex;
  
}

#top-banner{
  display: flex;
  justify-content: space-evenly;
  padding: 20px 0px;
  color:white;
  width: 100%;
  text-align: center;
  background-color: black;
}

#top-banner>.flex-container{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

#top-banner h6{
  margin: 0 10px;
  font-weight: bold;
  font-size: .5em;
}
.boton_a {
  margin:0px!important;
  padding:0px!important;
  text-decoration: none;
  font-size: 20px;
  color: white;
  background-color: black;
  border: 1px solid black;
}
.boton_a:hover {
  border: 1px solid #FF00FF;
}
.btn-aplicar {
  margin: 0 10px;
  padding: 3px 10px!important;
  background-color: #fff!important;
  color: #000!important;
  border-radius: 0!important;
  font-size: .5em!important;
}
.btn-aplicar:hover {
    background-color: #000!important;
    color: #fff!important;  
    border: 1px solid #BAFF43;
}
#lang{
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

#lang span{
  cursor: pointer;
}

#lang span:hover{
  color: #BAFF43;
}

@media screen and (min-width: 768px){
  #logo{
    height: 65px;
  }
  #top-banner h6{
    margin: 0 20px;
    font-size: 1rem;
  }

  .btn-aplicar{
    margin: 0 20px;
    padding: 3px 30px!important;
    font-size: 1rem!important;
  }
}
  

</style>
<script>
import {mapState} from 'vuex'
import { i18n } from '@/plugins/i18n'
export default {
  name: 'Navbar',
  data(){
    
    return{
      languages: [
      { locale: 'es', title: 'ES' },
      { locale: 'en', title: 'EN' }
    ],
      nextBootcamp: null,
      variant: 'blue'
    }
  },
  computed:{
    ...mapState(['nav']),
    date(){
      if(this.nextBootcamp != null && this.nextBootcamp.length != 0){
        let initDate = new Date(this.nextBootcamp[0].initDate)
        return initDate.getDate() + "/" + (initDate.getMonth() + 1)
      }else{
        return this.lang == 'es' ? "no disponible" : "no data"
      }
    },
    bootcampCode(){
      const bootcampTitle = this.nextBootcamp[0].title;
      const bootcampCode = bootcampTitle.slice(3);
      // console.log(bootcampCode)
      return bootcampCode;
    },
    name(){
      if (this.nextBootcamp != null && this.nextBootcamp.length != 0) {
      const bootcampTitle = this.nextBootcamp[0].title;
      const bootcampDate = this.formatBootcampDate(this.nextBootcamp[0].initDate);

      return `${bootcampDate} ${bootcampTitle}`;
      
      } else {
        return '';
      }
    },
    lang(){
      return i18n.locale
    },
    url() {
      return this.nextBootcamp[0].url
    }
  },
  methods:{
    formatBootcampDate(initDate) {
    const date = new Date(initDate);

    const monthNames = {
      es: [
        'ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO',
        'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'
      ],
      en: [
        'JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE',
        'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'
      ]
    };

    const day = date.getDate();
    const month = monthNames[this.lang][date.getMonth()];

    return this.lang === 'es' ? `${day} DE ${month}` : `${month} ${day}`;
  },
    changeLanguage(locale) {
      i18n.locale = locale
      localStorage.setItem('language', locale)
    },
    getNextBootcamp(){
       
      const strapiUrl = process.env.VUE_APP_STRAPI_URL;
            const apiKey = process.env.VUE_APP_API_KEY;
            fetch(`${strapiUrl}/api/bootcamps`, {
                headers: {
                'Authorization': `Bearer ${apiKey}`,
                }
            })
      .then(res => res.json())
      .then(json => {
        const today = new Date();
        this.nextBootcamp = json.filter(b => {
          if(!b.publishedAt){
            return null
          }
          const initDate = new Date(b.initDate);
          // Filtro por fecha y título
          return today <= initDate 
        });
      })
    }
  },
  beforeMount(){
    this.getNextBootcamp()
  }
}
</script>